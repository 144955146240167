<template>
  <div class="about_us">
    <v-container>
      <v-row justify="center" align-sm="center">
        <v-col cols="12" sm="12">
          <div class="rounded-lg title-bg py-2 px-4 mb-4">
            <h2 class="white--text">About us</h2>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align-sm="center">
        <v-col cols="12" sm="12">
          <v-img
            alt="100 miles realtors services"
            class="shrink mb-4"
            src="../assets/aboutus.jpg"
            transition="scale-transition"
            max-height="300"
            max-width="400"
          />
          <div class="body-1">
            <p class="text-justify">
              100 Miles Realtors is a Leading Real Estate Company since 1998. We
              are offering services Buying, Selling, Rental regarding Flats,
              Bungalow, Open Plots, Farms, Commercial and Residential
              Properties.
            </p>
            <p class="text-justify">
              It is essential to find the right and good land to meet the
              demands of residential plots and commercial lands. 100 MILES is
              the well known real estate agency that works to avail the services
              related to lands and plots for their customers. It believes in
              customer satisfaction and, therefore, it puts immense effort to
              fulfill the property requirements of their customers.
            </p>

            <p class="text-justify">
              100 Miles Realtors believe that one size does not fit all. We
              offer property consultancy by thoroughly understanding the
              requirement of our customers and providing relevant property
              options. Dealing in all type of properties.
            </p>

            <p class="text-justify">
              Plots and land prices are rising with the passing time. 100 MILES
              provides the best opportunities to their customers to purchase the
              plots and lands at an affordable cost.
            </p>

            <p class="text-justify">
              Our Strategic consulting advisors assists our clients withlong-run
              implementation of strategic real estate solutions. This includes
              strategic programming, strategic interpretation on all major
              property types, especially for institutional-level clients,
              identifying new business location, working out physical
              configurations and usage standards that will enable a client to
              operate at maximum efficiency. This business moves favour clients
              to meet the twin objectives of making quality property decisions
              and maximizing returns.
            </p>

            <p class="text-justify">
              Our services where our clients seek strategic decisions rely
              heavily on technical expertise, market knowledge and analytical
              skills which also the areas where our services apply significantly
              :
            </p>
            <v-list>
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon small v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content v-text="item.text"> </v-list-item-content>
              </v-list-item>
            </v-list>
            <p class="text-justify">
              Our services also include making policy recommendations, assess
              financial viability, do market analysis, devise business plans,
              action plan, master plan and work on scenario generation. We
              undertake property portfolio strategy, offer investment services
              and advice, and suggest the most strategic viable alternatives as
              per the requirements of the client brief.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        { text: "Business location selection", icon: "fas fa-certificate" },
        { text: "Portfolio strategy", icon: "fas fa-certificate" },
        { text: "Merger and acquisition strategy", icon: "fas fa-certificate" },
        {
          text: "Merger and acquisition due diligence",
          icon: "fas fa-certificate",
        },
        {
          text: "Allocation and Investment Strategies",
          icon: "fas fa-certificate",
        },
        {
          text: "Real Estate/Asset-Type Allocation",
          icon: "fas fa-certificate",
        },
      ],
    };
  },
};
</script>

<style>
.title-bg {
  background-image: linear-gradient(235deg, #c2e4fe 0%, #0190ff 100%);
}
</style>
